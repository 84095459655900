<template>
  <div>
    <PageHeader>
      <template slot="title">戻る</template>
      <template slot="content">見積一覧</template>
    </PageHeader>
    <template
      >見積一覧画面です。この画面で〇〇条件で見積を検索できます。
    </template>
    <el-divider></el-divider>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="20" :lg="16" :xl="12">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-input v-model="project_name">
              <template slot="prepend">案件名</template>
            </el-input>
          </el-col>
          <el-col :span="12">
            <el-input v-model="estimate_name">
              <template slot="prepend">見積名</template>
            </el-input>
          </el-col>
        </el-row>
        <br />
        <el-row :gutter="40">
          <el-col :span="12">
            <el-input v-model="customer_name">
              <template slot="prepend">得意先</template>
            </el-input>
          </el-col>
          <el-col :span="12">
            <el-select
              v-model="created_at_year"
              clearable
              placeholder="作成年"
              style="width:50%"
            >
              <el-option
                v-for="item in years"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <el-select
              v-model="created_at_month"
              clearable
              placeholder="作成月"
              style="width:50%"
            >
              <el-option
                v-for="item in months"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="20" justify="left">
      <el-col :span="24">
        <el-divider></el-divider>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-table
          :data="
            EstimateTableData.filter(
              data =>
                !project_name ||
                data.project_name
                  .toLowerCase()
                  .includes(project_name.toLowerCase())
            )
              .filter(
                data =>
                  !estimate_name ||
                  data.estimate_name
                    .toLowerCase()
                    .includes(estimate_name.toLowerCase())
              )
              .filter(
                data =>
                  !customer_name ||
                  data.customer_name
                    .toLowerCase()
                    .includes(customer_name.toLowerCase())
              )
              .filter(
                data =>
                  !created_at_year ||
                  data.created_at
                    .slice(0, 4)
                    .toLowerCase()
                    .includes(created_at_year.toLowerCase())
              )
              .filter(
                data =>
                  !created_at_month ||
                  data.created_at
                    .slice(4, 7)
                    .toLowerCase()
                    .includes(created_at_month.toLowerCase())
              )
          "
          style="width: 100%"
        >
          <!--        修改element自带空表格是暂无数据的提示-->
          <template slot="empty">
            <p>見積データがありません</p>
          </template>
          <el-table-column label="見積書No." width="150%">
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{
                scope.row.estimate_pdf_num
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="見積名" width="400%">
            <template slot-scope="scope">
              <a
                @click="handleEditEstimate(scope.$index, scope.row)"
                class="rowTxt"
                >{{ scope.row.estimate_name }}</a
              >
            </template>
          </el-table-column>
          <el-table-column label="案件名" width="400%" show-overflow-tooltip>
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{
                scope.row.project_name
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="得意先" width="300%" show-overflow-tooltip>
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{
                scope.row.customer_name
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="税込金額" width="250%" align="right">
            <template slot-scope="scope">
              <el-popover trigger="hover" placement="top-start">
                <p>
                  税抜金額:
                  <span style="float: right">{{ scope.row.sub_total }}</span>
                </p>
                <p>
                  税金: <span style="float: right">{{ scope.row.tax }}</span>
                </p>
                <p>
                  税込金額:
                  <span style="float: right">{{ scope.row.total }}</span>
                </p>
                <div slot="reference" class="name-wrapper">
                  <el-tag size="medium" effect="plain">{{
                    scope.row.total
                  }}</el-tag>
                </div>
              </el-popover>
            </template>
          </el-table-column>
          <el-table-column label="作成年月" width="150%" show-overflow-tooltip>
            <template slot-scope="scope">
              <span style="margin-left: 10px">{{
                scope.row.created_at.slice(0, 7)
              }}</span>
            </template>
          </el-table-column>
          <el-table-column label="進捗" show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1"
                >見積⇨<span style="color :#0A3D64">発注</span></span
              >
              <span v-else-if="scope.row.status == 2"
                >見積⇨<span style="color :#0A3D64"
                  >発注⇨<span style="color :#EAAD5A">納品</span></span
                ></span
              >
              <span v-else-if="scope.row.status == 3"
                >見積⇨<span style="color :#0A3D64"
                  >発注⇨<span style="color :#EAAD5A"
                    >納品⇨<span style="color :#A8B293">検収</span></span
                  ></span
                ></span
              >
              <span v-else-if="scope.row.status == 4"
                >見積⇨<span style="color :#0A3D64"
                  >発注⇨<span style="color :#EAAD5A"
                    >納品⇨<span style="color :#A8B293"
                      >検収⇨<span style="color :#78922C">請求</span></span
                    ></span
                  ></span
                ></span
              >
              <span v-else-if="scope.row.status == 5"
                >見積⇨<span style="color :#DF8053">失注</span></span
              >
              <span v-else>見積</span>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getEstimateDatas, searchEstimateData } from "api/estimates";
import PageHeader from "components/common/menu/PageHeader";
import { Message } from "element-ui";
export default {
  name: "EstimateList",
  components: {
    PageHeader
  },
  data() {
    return {
      project_name: "",
      estimate_name: "",
      customer_name: "",
      created_at_year: "",
      created_at_month: "",
      is_delete: "",
      estimate_code: "",
      EstimateTableData: [],
      years: [
        {
          value: "2021",
          label: "2021年"
        },
        {
          value: "2022",
          label: "2022年"
        },
        {
          value: "2023",
          label: "2023年"
        },
        {
          value: "2024",
          label: "2024年"
        },
        {
          value: "2025",
          label: "2025年"
        },
        {
          value: "2026",
          label: "2026年"
        },
        {
          value: "2027",
          label: "2027年"
        },
        {
          value: "2028",
          label: "2028年"
        },
        {
          value: "2029",
          label: "2029年"
        },
        {
          value: "2030",
          label: "2030年"
        }
      ],
      months: [
        {
          value: "01",
          label: "一月"
        },
        {
          value: "02",
          label: "二月"
        },
        {
          value: "03",
          label: "三月"
        },
        {
          value: "04",
          label: "四月"
        },
        {
          value: "05",
          label: "五月"
        },
        {
          value: "06",
          label: "六月"
        },
        {
          value: "07",
          label: "七月"
        },
        {
          value: "08",
          label: "八月"
        },
        {
          value: "09",
          label: "九月"
        },
        {
          value: "10",
          label: "十月"
        },
        {
          value: "11",
          label: "十一月"
        },
        {
          value: "12",
          label: "十二月"
        }
      ]
    };
  },
  created: function() {
    this.getEstimateDatas();
  },
  methods: {
    getEstimateDatas() {
      let values = {};
      values["user_name"] = localStorage.getItem("user_name");
      getEstimateDatas(values)
        .then(res => {
          this.EstimateTableData = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    searchEstimateData() {
      let values = {};
      values["project_code"] = this.ruleForm.project_code;
      values["project_name"] = this.ruleForm.project_name;
      values["customer_name"] = this.ruleForm.customer_name;
      values["customer_code"] = this.ruleForm.customer_code;
      values["is_delete"] = this.ruleForm.is_delete;
      searchEstimateData(values)
        .then(res => {
          if (res.data.status === 1) {
            this.EstimateTableData = res.data.data;
            Message({
              showClose: true,
              message: res.data.message,
              type: "success"
            });
          } else {
            Message({
              showClose: true,
              message: res.data.message,
              type: "error"
            });
          }
        })
        .catch(err => {
          console.log(err);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.searchEstimateData();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleEditEstimate(index, row) {
      this.$router.push({
        path: "Projectform",
        query: { project_code: row.project_code }
      });
    }
  }
};
</script>

<style scoped>
</style>
